import globalHistory from "./globalHistory";
import globalShotMediaData from "./globalShotMediaData";
import globalShotScreenShotData from "./globalShotScreenShotData";
import globalShotFontCodeData from "./globalShotFontCodeData";
import globalShotFontFunData from "./globalShotFontFunData";
import globalShotDeviceData from "./globalShotDeviceData";
import globalCodeSupport from "./globalCodeSupport";
import globalDownloadFormat from "./globalDownloadFormat";

const global = {}

global.App = {
  name: 'DevShots',
  desc: 'Generate Beautiful Images of Developers Markdown Code to Share',
  version: '25.01.20',
  copyright: 'DevShots.app',
  lastUpdate: '2025-01-20',
};

global.History = globalHistory;
global.ShotMediaData = globalShotMediaData;
global.ShotScreenShotData = globalShotScreenShotData;
global.ShotFontCodeData = globalShotFontCodeData;
global.ShotFontFunData = globalShotFontFunData;
global.ShotDeviceData = globalShotDeviceData;
global.CodeSupport = globalCodeSupport;
global.DownloadFormat = globalDownloadFormat;

global.TestContent = {
  contentHTML: '<h1>DevShots</h1><div>Tool to Create Code Share Image</div><copyright>@DevShots.app 2023</copyright>',
  contentJSON: '{"code":200,"msg":"success","trace_id":"81794bed39eb","data":{"copyright":"DevShots.app"}}',
  contentJS: 'function goHome(){if(i!=j){return i===j;}else{return i=>j}}',
  contentCSS: '[id*="processbar-"] {border-radius:20px;transition: all 2s;-webkit-transition: all 2s;}',
  //  contentSQL: 'select * from tblUser limit 1;',
  contentMD: ' # ' + global.App.name + '\n #### ' + global.App.desc + '\n #### Version ' + global.App.version + ' \n - ' + global.History[0].updateContent + '\n - ' + global.History[1].updateContent + '\n - ' + global.History[2].updateContent + '\n - ' + global.History[3].updateContent + '\n - ' + global.History[4].updateContent + '\n - ' + global.History[5].updateContent + '\n\n ### ' + global.App.copyright,
  contentCN: '## 浪淘沙·九曲黄河万里沙\n  ### 唐朝·刘禹锡\n  ## \n  ## 九曲黄河万里沙，\n  ## 浪淘风簸自天涯。\n  ## 如今直上银河去，\n  ## 同到牵牛织女家。\n ## ',
  contentJP:'## 古池や\n## 蛙飛びこむ\n## 水の音'
};
global.isScreenShot = function(mediaKey){

  for (let i = 0; i < global.ShotScreenShotData.length; i++) {
    if (global.ShotScreenShotData[i].key === mediaKey) {
      return true;
    }
  }
  return false;
}
global.updateShotSize = function(shotConfig) {
  const mediaKey = shotConfig.shotMedia;
  var mediaItem = {};
  global.ShotMediaData.forEach(element => {
    if (element.key === mediaKey) {
      mediaItem = element;
    }
  });

  if(!mediaItem.key){
    global.ShotScreenShotData.forEach(element => {
      if (element.key === mediaKey) {
        mediaItem = element;
      }
    });
  }

  // console.log(global.ShotScreenShotData);
  // console.log(mediaKey);
  // console.log(mediaItem);
  const mediaAspect = shotConfig.shotAspect;
  document.documentElement.style.setProperty('--shot-aspect', shotConfig.shotAspect);
  //const shotPadding = shotConfig.shotPadding;
  //console.log(mediaAspect);
  if (mediaAspect === 'square') {
    shotConfig.shotWidth = mediaItem.squareWidth;
    shotConfig.shotHeight = mediaItem.squareHeight;
    document.documentElement.style.setProperty('--shot-width', mediaItem.squareWidth + 'px');
    document.documentElement.style.setProperty('--shot-height', mediaItem.squareHeight + 'px');
  }
  if (mediaAspect === 'landscape') {
    shotConfig.shotWidth = mediaItem.landscapeWidth;
    shotConfig.shotHeight = mediaItem.landscapeHeight;
    document.documentElement.style.setProperty('--shot-width', mediaItem.landscapeWidth + 'px');
    document.documentElement.style.setProperty('--shot-height', mediaItem.landscapeHeight + 'px');
  }
  if (mediaAspect === 'portrait') {
    shotConfig.shotWidth = mediaItem.portraitWidth;
    shotConfig.shotHeight = mediaItem.portraitHeight;
    document.documentElement.style.setProperty('--shot-width', mediaItem.portraitWidth + 'px');
    document.documentElement.style.setProperty('--shot-height', mediaItem.portraitHeight + 'px');
  }
  return shotConfig;
}
export default global;
