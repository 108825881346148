import './BoxHeader.scss';

import React from 'react';
import { useState, useEffect } from 'react';
import { Dropdown, Col, Row, Space, Layout } from 'antd';
import { GithubOutlined, DownOutlined } from '@ant-design/icons';

import { useTranslation } from "react-i18next";

export default function BoxHeader({ handleHeader }) {
    const { t } = useTranslation();
    const { Header } = Layout;
    //const [lang, setLang] = useState('en');
    const [langLabel, setLangLabel] = useState('English');

    useEffect(() => {
        const lang = JSON.parse(localStorage.getItem('lang'));
        if (lang) {
            //setLang(lang);
            if(lang==='cn'){
                setLangLabel('中文');
            }
            if(lang==='jp'){
                setLangLabel('日本語');
            }
            if(lang==='kr'){
                setLangLabel('한국어');                
            }
            if(lang==='fr'){
                setLangLabel('Français');                
            }
        }
    }, []);

    // const onChangeLang = (e) => {
    //     let _lang = e.target.value;
    //     setLang(_lang);
    //     handleHeader(_lang)
    // };


    const handleMenuClick = (e) => {
        let _lang = e.key;
       //setLang(_lang);
        handleHeader(_lang);
        setLangLabel(e.domEvent.target.innerText);
    }

    const items = [
        {
            key: 'en',
            label: 'English',
        },
        {
            key: 'cn',
            label: '中文',
        },
        {
            key: 'jp',
            label: '日本語',
        },
        {
            key: 'kr',
            label: '한국어',
        },
        {
            key: 'fr',
            label: 'Français',
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    return (
        <Header>
            <Row className='head-box'>
                <Col flex={3}>
                    <div className="logo">
                        <div className="logo-image"></div>
                        <div className='logo-title'>Dev</div>
                        <div className='logo-title2'>Shots</div>
                        <div className='logo-text'>{t('head.desc', 'A Free Tool to Create Elegant Code Shots for Developer')}</div>
                    </div>
                </Col>
                <Col flex={2}><div className="menu">
                    <Space>
                        {/* <Button type='primary'>Dev2</Button>
                      <Button type='text'>Dev1</Button> */}
                        <div className='github-icon'><a href="https://github.com/70Apps/DevShots" className="logo-github"><GithubOutlined /></a></div>
                        <Dropdown.Button
                            icon={<DownOutlined />}
                            menu={menuProps}
                        >{langLabel}
                        </Dropdown.Button>
                        {/* <Radio.Group onChange={onChangeLang} value={lang}>
                            <Radio.Button value="en">
                                English
                            </Radio.Button>
                            <Radio.Button value="cn">
                                中文
                            </Radio.Button>
                        </Radio.Group> */}
                    </Space>
                </div></Col>
            </Row>
        </Header>
    );
}
